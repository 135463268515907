const data = {
  logo: "/assets/images/logo.png",
  title: "Proyecto de villas estilo town house de 2 niveles con piscina y terraza privada",
  description: "<p>Complejo residencial de villas de estilo Town House, ideal parapersonas que desean invertir en la zonade Bávaro Punta Cana, y que tienen lavisión de un estilo de vida relajado ytranquilo, disfrutando de los múltiplesbeneficios que ofrece esta ciudadcostera.</p><p>El complejo incluye unas 28 villas de dosniveles para ser adquiridas en planos,con un sistema de financiamientocómodo para los adquirientes y a serrecibidas en 12 meses a partir de lareserva.</p>",
  bg_image_project: "assets/villas-vera-cana/images/villas-town-house-bavaro-calle.jpeg",
  bg_image_beach: "assets/villas-vera-cana/images/playa-bavaro-punta-cana.jpg",
  images_renders: [
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-calle.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-piscina.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-cocina-comedor.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-cocina.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-sala.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-vista-dentro-fuera.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-piscina-view.jpeg"
    },
    {
      image: "assets/villas-vera-cana/images/villas-town-house-bavaro-piscina-aire.jpeg"
    }
  ],
  images_construction: [
    {
      image: "assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-frontal.jpg"
    },
    {
      image: "assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-piscina.jpg"
    },
    {
      image: "assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro.jpg"
    }
  ],
  ville_amenities: [
    '3 Habitaciones',
    'Medio Baño para visitas',
    '2 Balcones',
    'Sala de estar',
    'Cocina modular',
    'Área de lavado',
    '3 Parqueos',
    'Comedor con vista al patio',
    'Terraza techada con picuzzi y área de BBQ',
    'Habitación principal con su baño',
    'Baño común para las habitaciones secundarias',
  ],
  project_amenities: [
    'Residencial cerradocon seguridad 24/7',
    'Amplias áreas alaire libre paraesparcimiento',
    'Portón eléctricopara accesocontrolado.',
    'Excelente ubicaciónen la ciudad.',
  ],
  location: {
    title: 'Excelente ubicación',
    description: 'El complejo se encuentra en las inmediaciones del Boulevard Turístico del Este, el cual conecta las principales áreas de comercio, entretenimiento y turismo de la zona. Próximo a Down Town Punta Cana, y a solo 15 minutos de las principales playas de Bávaro y del Aeropuerto Internacional de Punta Cana.',
    latitude: '18.6933',
    longitude: '-68.4563',
    map_location: 'assets/villas-vera-cana/images/ubicacion-proyecto-villas-town-house-bavaro.jpg'
  },
  starting_price: "USD $185,000",
  address: '',
  phone: '809-605-0699',
  whatsapp: '809-605-0699',
  email: 'ventas@superhogar.net',
  facebook: 'https://www.facebook.com/SuperHogar/?ref=br_rs',
  twitter: '',
  instagram: 'https://www.instagram.com/superhogarrd',
  linkedin: '',
  youtube: 'https://www.youtube.com/channel/UCLzJpdrplA9Oy6K1tVf8ZHw',
  tiktok: '',
  // Add more data as needed
};

export default data;
