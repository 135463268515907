import React from 'react';
import LandingPageBuilder from './pages/LandingPageBuilder';
import './scss/App.scss';

const App = () => {
  return (
        <div className="App">
          <LandingPageBuilder />
        </div>
  );
}

export default App;
