const data =     {
  project_name: 'Coral del Sur',
  project_slug: 'projecto-de-apartamentos-coral-del-sur',
  logo: "/assets/1.png",
  title: "Proyecto de 72 apartamentos, 9 Edificios de 96 metros cuadrados con patio en el 1er piso y terraza / jacuzzi en el 4to piso.",
  description: "<p><strong>AREAS:</strong></p>" +
        "<ul>"+
        "    <li>Sala&nbsp;</li>"+
        "    <li>Comedor con vista exterior</li>"+
        "    <li>Cocina&nbsp;</li>"+
        "    <li>2 Habitaciones baño Común</li>"+
        "    <li>1 Habitación Principal, W.C + Balcón</li>"+
        "    <li>Area de Lavado.</li>"+
        "</ul>"+
        "<p><strong>1er Nivel:</strong></p>"+
        "<p>Patios incluidos.</p>"+
        "<p><strong>4to Nivel:</strong></p>"+
        "<p>Azotea, con Jacuzzi y Gazebo Incluido.</p>"+
        "<p><strong>Terminaciones:&nbsp;</strong></p>"+
        "<ul>"+
        "    <li>Puerta Principal en Madera Preciosa</li>"+
        "    <li>Puertas Secudarias, MDF</li>"+
        "    <li>Piso, Porcelanato Español</li>"+
        "    <li>Topes de cocina Granito</li>"+
        "    <li>Cocina Modular MDF</li>"+
        "    <li>Baños, cerámicas Importada</li>"+
        "    <li>Pandereta de vidrio, Inodoros y lavamanos Regular.</li>"+
        "    <li>Terminaciones en Sheetrock y madera sintética, es Opcional, y tendrá un costo adicional</li>"+
        "    <li>Closet en habitación MDF</li>"+
        "</ul>",
  apartment_areas: 
  "<ul>"+
  "    <li>Sala&nbsp;</li>"+
  "    <li>Comedor con vista exterior</li>"+
  "    <li>Cocina&nbsp;</li>"+
  "    <li>2 Habitaciones baño Común</li>"+
  "    <li>1 Habitación Principal, W.C + Balcón</li>"+
  "    <li>Area de Lavado.</li>"+
  "</ul>",
    apartment_areas_1st_floor: "<p>Patios incluidos.</p>",
    apartment_areas_4th_floor: "<p>Azotea con Jacuzzi y Gazebo Incluido.</p>",
    terminations: 
    "<ul>"+
    "    <li>Puerta Principal en Madera Preciosa</li>"+
    "    <li>Puertas Secudarias, MDF</li>"+
    "    <li>Piso, Porcelanato Español</li>"+
    "    <li>Topes de cocina Granito</li>"+
    "    <li>Cocina Modular MDF</li>"+
    "    <li>Baños, cerámicas Importada</li>"+
    "    <li>Pandereta de vidrio, Inodoros y lavamanos Regular.</li>"+
    "    <li>Terminaciones en Sheetrock y madera sintética, es Opcional, y tendrá un costo adicional</li>"+
    "    <li>Closet en habitación MDF</li>"+
    "</ul>",
  keywords: "inmobiliaria, real estate, propiedades, venta, alquiler, santo domingo, punta cana, bavaro, república dominicana, coral del sur, apartamentos",
  image_project: "/assets/coral-del-sur/4.jpeg",
  image_beach: "/assets/coral-del-sur/1.jpeg",
  images_renders: [
    {
      image: "/assets/coral-del-sur/1.jpeg"
    },
    {
      image: "/assets/coral-del-sur/2.jpeg"
    },
    {
      image: "/assets/coral-del-sur/3.jpeg"
    },
    {
      image: "/assets/coral-del-sur/4.jpeg"
    },
    {
      image: "/assets/coral-del-sur/5.jpeg"
    },
    {
      image: "/assets/coral-del-sur/6.jpeg"
    },
    {
      image: "/assets/coral-del-sur/7.jpeg"
    },
    {
      image: "/assets/coral-del-sur/8.jpeg"
    },
    {
      image: "/assets/coral-del-sur/9.jpeg"
    },
    {
      image: "/assets/coral-del-sur/10.jpeg"
    },
    {
      image: "/assets/coral-del-sur/11.jpeg"
    },
    {
      image: "/assets/coral-del-sur/12.jpeg"
    },
    {
      image: "/assets/coral-del-sur/13.jpeg"
    },
    {
      image: "/assets/coral-del-sur/14.jpeg"
    }
  ],
  images_carousel: [
    {
        image: "/assets/coral-del-sur/seguridad-control-acceso.jpg",
        title: "Seguridad",
        description: "Proyecto cerrado con seguridad 24/7 y control de acceso."
    },
    {
        image: "/assets/coral-del-sur/area-social-piscina-parqueo-visitas.jpg",
        title: "Área Social",
        description: "Amplias áreas al aire libre con piscina, terraza y parqueo para visitantes."
    },
    {
        image: "/assets/coral-del-sur/amplios-espacios-cocina-comedor.jpg",
        title: "Amplios Espacios",
        description: "Cocina y comedor con vista exterior."
    },
    {
        image: "/assets/coral-del-sur/habitaciones.jpg",
        title: "Habitaciones",
        description: "Habitaciones con baño común y principal con W.C y balcón."
    },
  ],
  amenities: [
    'Puerta Principal en Madera Preciosa',
    'Puertas Secudarias, MDF',
    'Piso, Porcelanato Español',
    'Topes de cocina Granito',
    'Cocina Modular MDF',
    'Baños, cerámicas Importada',
    'Pandereta de vidrio, Inodoros y lavamanos Regular.',
    'Terminaciones en Sheetrock y madera sintética, es Opcional, y tendrá un costo adicional',
    'Closet en habitación MDF',
  ],
  project_amenities: [
    "<ul>" +
        "<li>Residencial cerrado con seguridad 24/7.</li>"+
        "<li>Amplias áreas al aire libre para esparcimiento.</li>"+
        "<li>Portón eléctrico para acceso controlado.</li>"+
        "<li>Excelente ubicación cerquita de la playa.</li>"+
    "</ul>"
  ],
  location: {
    title: 'Excelente ubicación',
    description: 'El proyecto se encuentra a 5 minutos de la playa de Najayo en la carretera de Palenque.',
    latitude: '18.32622',
    longitude: '-70.10019',
    map_location: '/assets/coral-del-sur/7.jpeg'
  },
  starting_price: "USD $128,000",
  reserve_price: "USD $2,000",
  delivery_time: 'Octubre 2025',
  address: '',
  phone: '809-855-1288',
  whatsapp: '809-855-1288',
  whatsapp_number: '18098551288',
  email: 'nelson.lucas@facilcasas.com',
  facebook: '',
  twitter: '',
  instagram: 'https://www.instagram.com/facilcasasrd',
  linkedin: '',
  youtube: '',
  tiktok: '',
  // Add more data as needed
};

export default data;
