import React from "react";
import Card from "react-bootstrap/Card"; 
import Row from "react-bootstrap/Row"; 
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "react-multi-carousel/lib/styles.css";
import data from "../data/proyecto-coral-del-sur";
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction";
import PhotoGallery from "../components/PhotoGallery";
import HomeCarousel from "../components/HomeCarousel";
import QuickLeadForm from "../components/QuickLeadForm";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Map from "../components/Map";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"; // mobile

const LandingPageBuilder = () => {

    const [index, setIndex] = React.useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }
    return (
        <>
            <Helmet>
                <title>{data.project_name} - Facil Casas</title>
                <meta name="description" content={data.description} />
                <meta name="keywords" content={data.keywords} />
                <meta property="og:title" content={data.title} />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${data.project_slug}`} />
                <meta property="og:site_name" content={`${data.project_name} - Facil Casas`} />
                <meta property="og:description" content={data.description} />
            </Helmet>
            <Navbar expand="lg" className="bg-light" sticky="top">
                <Container className="justify-content-center">
                    <Nav className="justify-content-center" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link className="btn" href={`https://wa.me/${data.whatsapp_number}`}><FontAwesomeIcon className='fa-1x' icon={faWhatsapp} /> {data.whatsapp}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="btn" href="#amenidades"><FontAwesomeIcon className='fa-1x' icon={faEnvelope} /> {data.email}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </Navbar>
            <Container className="g-0 mb-md-5 py-md-5 hero-banner" fluid style={{ backgroundImage: `url(${data.image_project})`}}>    
                <Container >
                    <Row className="heading-container d-flex justify-content-center text-white">
                        <Col md={7} className="g-0">
                            <h1 className="heading-title">{data.project_name}</h1>
                            <h5 className="mx-md-5">{data.title}</h5>
                            <h4 className="mb-md-5 mx-5">Precios desde: {data.starting_price} | Reserve con {data.reserve_price}</h4>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <Container fluid>
                <Map
                    isMarkerShown
                    lat={data.location.latitude}
                    lng={data.location.longitude}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCR7OnUqD4qBXrFCn9paHKulk514PeQ02I"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </Container> */}
            <Container className="g-0 mb-md-5 py-md-5">
                <QuickLeadForm projectData={data} />
            </Container>
            <Container fluid className="mb-5">
                <Row className="align-items-center">
                    <Carousel className="g-0" fade activeIndex={index} onSelect={handleSelect} style={{ width: '100%' }}>
                        {data.images_carousel.map((image, index) => (
                            <Carousel.Item key={index}>
                                <Image className="carousel-image" src={image.image} style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                                <Carousel.Caption className="s">
                                    <h3>{data.images_carousel[index].title}</h3>
                                    <p>{data.images_carousel[index].description}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>

                </Row>
            </Container>
            <Container className="mb-5 py-5 bg-cover-image project-amenities" fluid style={{backgroundImage: `url(${data.image_beach})`, width: "100%", height: "auto"}}>
                <Container>
                    <Row id="amenidades">
                        <div className="title-container mb-3 mb-md-5">
                            <h2 className="mx-sm-4 animate__animated animate__pulse display-4">Amenidades del proyecto</h2>
                        </div>
                    </Row>
                    
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 2}}
                    >
                        <Masonry>
                            <Card>
                                <Card.Header as="h5">
                                    Amenidades
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.apartment_areas }}></Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header as="h5">
                                    Beneficios 1er Nivel
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.apartment_areas_1st_floor }}></Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header as="h5">
                                    Beneficios 4to Nivel
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.apartment_areas_4th_floor }}></Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header as="h5">
                                    Facilidades
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.project_amenities }}></Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header as="h5">
                                    Terminaciones
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.terminations }}></Card.Text>
                                </Card.Body>
                            </Card>
                        
                        </Masonry>
                    </ResponsiveMasonry>
                    
                    <Row className="d-flex justify-content-center" id="ubicacion">
                        <div className="title-container my-3 my-md-5">
                            <h2 className="display-4">Ubicación</h2>
                            <p className="h5 mx-3 mx-md-5">{data.location.description}.</p>
                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${data.location.latitude},${data.location.longitude}`} className="btn btn-primary">Ver Ubicación</a>
                        </div>
                    </Row>
                </Container>
            </Container>
            <div id="galeria">
                <PhotoGallery images={data.images_renders} title="Galería de imágenes" />
            </div>
            <div id="contacto">
                <CallToAction title="¿Quieres conocer mas?" whatsapp={data.whatsapp_number} />
            </div>
            {/* <div id="proceso">
                <PhotoGallery images={data.images_renders} title="Proceso de construcción" />
            </div> */}
        </>
    );
}

export default LandingPageBuilder;
