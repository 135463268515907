import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

const masonryOptions = {
    transitionDuration: 0
};

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
  };

const PhotoGallery = ({ images, title }) => {
  console.log('images', images)
    const imageElements = images.map((image, index) => (
        <div key={index}><Image className='rounded-4' src={image.image} /></div>
    ));

    console.log('imageElements', imageElements)

  return (
    <Container>
        <Row>
            <Col>
                <h1 className='text-center display-3'>{title}</h1>
            </Col>
        </Row>
      <Row className='justify-content-center'>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {imageElements}
        </Masonry>
      </Row>
    </Container>
  );
};

export default PhotoGallery;